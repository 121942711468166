import { request as axios, request } from '@/util/request';
const clean = require('bmh/clean-empty-obj');
const getUrl = require('bmh/url-with-param');
const API = {
    warehouse: '/boss/factory/warehouse'
}
async function getWarehouse(send_data: any = {}, current: number = 1, limit?: any) {
    const _send_data = {
        ...send_data,
        limit,
        current,
    };
    clean(_send_data);
    const url = getUrl(API.warehouse, _send_data);
    const res = await axios.get(url)
    if (res.data.length === 0) return res
    const { data: { detail } } = res
    res.data.detail = detail.map((item) => {
        return {
            ...item,
            status: item.status === 1 ? true : false,
        }
    })
    return res;
}
async function postWarehouse(params: any) {
    return request.post(API.warehouse, params)
}
async function putWarehouse(params: any, id?: number) {
    return request.put(API.warehouse + '/' + id, params)
}
async function putWarehouseStatus(params: any, id?: number) {
    return request.put('/boss/factory/warehouse/status/' + id, params)
}
export {
    getWarehouse,
    postWarehouse,
    putWarehouse,
    putWarehouseStatus
}